<template>
  <div class="simpleRule">
    <h4>{{ $t(title) }}</h4>
     <div class="col-sm-12">
         <button @click="createRow" class="btn btn-success">{{$t('Создать правило')}}</button>
     </div>
    <div class="col-sm-12">
      <el-table
        stripe
        style="width: 100%;"
        :data="tableData"
        border
        v-if="tableColumns && tableData && tableData.length > 0"
        :empty-text="$t('Нет данных!')"
      >
        <el-table-column
          v-for="column in tableColumns"
          :key="column.label"
          :min-width="column.minWidth"
          :prop="column.prop"
          :label="column.label"
          :class-name="hasFieldInData(column.prop) ? '' : 'errortable'"
        >
          <template slot-scope="props">
            {{
              column.prop in props.row
                ? props.row[column.prop]
                : $t("ОШИБКА! Данные для поля не найдены")
            }}
          </template>
        </el-table-column>
        <el-table-column
          :min-width="80"
          width="120"
          fixed="right"
          align="center"
          :label="$t(textAction)"
          class="noHiddenText"
          class-name="noHidden"
        >
          <template slot-scope="props">
            <span
              class="btn-warning btn-simple btn-link tableBtn mr-2"
              @click.prevent="showModalEdit(props.row)"
            >
              <i class="table_icon icon_eye" v-tooltip="$t('Редактировать')"></i
            ></span>

            <a
              class="btn-danger btn-simple btn-link tableBtn"
              @click="handleDelete(props.row[fieldId], props.row)"
              ><i class="table_icon icon_delete" v-tooltip="$t('Удалить')"></i
            ></a>
          </template>
        </el-table-column>
      </el-table>

      <el-dialog
        center
        :visible.sync="showModal"
      >
        <simple-rule
          title="Редактирование сложного правила"
          v-if="showModal && activeItem && activeItem.attrList"
          :loadData="activeItem"
          :pageMode="pageMode"
          :isCreateForm="false"
          :setDataAttrList="setDataAttrList"
        />
        <span slot="footer" class="dialog-footer">
          <button class="btn btn-danger" @click="closeModal">{{$t('Закрыть')}}</button>
          <button class="btn btn-success ml-10" @click="saveData">{{$t('Сохранить')}}</button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn } from "element-ui";

import { Dialog, MessageBox } from "element-ui";
import clearReactive from "../../../../../helpers/clearReactive";
import SimpleRule from "./SimpleRule.vue";
import Vue from "vue"
import swal from "sweetalert2";
import {api} from "../../../../../util/api"
import {API_METHODS} from "../../../../../constant"
import { generateUUID } from '../../../../../helpers/generateUUID';

export default {
  name: "TableRuleData",
  components: {
    [Table.name]: Table,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    SimpleRule,
    [Dialog.name]: Dialog
  },
  props: {
    pageMode: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: "Табличные настройки правила"
    },
    isCreateForm: {
      type: Boolean,
      required: true
    },
    loadData: {
      type: Object,
      required: true
    },
    prefixEdit: {
      type: String
    },
    prefixShow: {},
    textAction: { type: String, default: "Действия" },
    fieldId: {
      type: String,
      default: "id"
    },
    setDataTable: {
      type: Function,
      required: true
    }
  },
 
  data() {
    return {
      showModal: false,
      activeItem: {attrList: []},
    };
  },
  methods: {
   async handleDelete(item, row) {
     swal({
        title: this.$t("Вы уверены?"),
        text: this.$t("Вы не сможете вернуть это!"),
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: this.$t("Да удалить!"),
        cancelButtonText: this.$t("Закрыть"),
        buttonsStyling: false
      }).then(async res => {
        if (res.value && res.value == true) {
          let GroupId = row.data[0].groupId
          let paramsIn = {
            self: this,
            PolicyRuleId: this.$route.params.PolicyRuleId,
            PolicyId: this.$route.params.PolicyId,
            GroupId: GroupId
          }  
          await api(API_METHODS.DELETE_RULE_ITEM_TABLE, paramsIn)
          this.setDataTable()
        }
      }); 
    },
    hasFieldInData(field) {
      if (this.tableData && this.tableData.length > 0) {
        return this.tableData[0] && field in this.tableData[0] ? true : false;
      }
      return true;
    },
    showModalEdit(item) {
    
      this.activeItem.attrList = item.data
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.activeItem.attrList = []
    },
    setDataAttrList(newVal) {
      this.activeItem.attrList = newVal
    },
    async saveData() {
      const newDataAttrList = clearReactive(this.activeItem.attrList)
      this.closeModal()
      let paramsIn = {
        self: this,
        PolicyRuleId: this.$route.params.PolicyRuleId,
        PolicyId: this.$route.params.PolicyId,
        datasend: newDataAttrList
      }
      await api(API_METHODS.UPDATE_RULE_ITEM_TABLE, paramsIn)
      this.setDataTable()
    },
    async createRow() {
       const paramsIn = {
         self: this,
         PolicyRuleId: this.$route.params.PolicyRuleId,
         PolicyId: this.$route.params.PolicyId,
       } 
       const getClearData = await api(API_METHODS.GET_NEWRULE_ITEM_TABLE, paramsIn, this.$store.state) 
       const addUuid = getClearData.object.map(item=>{
         return {
           ...item,
           uuid: generateUUID()
         }
       })


       this.activeItem.attrList = addUuid
       this.showModal = true;
     
    }
  },
  computed: {
    tableColumns() {
      let res = clearReactive(this.loadData.attrTableList);
      if (res && res.length > 0) {

        res = res[0].map((item, index) => {
    
          return {
            label: item.ruleItemName,
            minWidth: "20%",
            prop: "field" + index
          };
        });
        return res;
      }
      return [];
    },
    tableData() {
      const allData = clearReactive(this.loadData.attrTableList);
      const covertData = [];
      if (allData && allData.length > 0) {
        allData.forEach((row, indexRow) => {

          let converRow = { id: indexRow, data: row  };

          row.forEach((item, index) => {
  
           converRow["field" + index] = item.displayValue;
          });
          covertData.push(converRow);
        });
        return covertData;
      }
      return [];
    }
  }
};
</script>

<style lang="scss" scoped>
.ml-10{
  margin-left: 10px;
}
</style>
