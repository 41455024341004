/**
 * генератор случайных значений
 */
export const generateUUID = () => {
  let res = "xxxxxxxx6xxxx64xxx6yxxx6xxxxxxxxxxxx".replace(/[xy]/g, function(
    c
  ) {
    let r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  return res;
};
