<template>
  <div class="simpleRule"  v-if="loadData && loadData.attrList && loadData.attrList.length > 0 ">
    <h4>{{$t(title)}}</h4>
    <dynamic-generator-form
      v-if="converDataToShemeGenerate"
      :form="tempLocalData"
      :configData="converDataToShemeGenerate"
      :isCreateForm="isCreateForm"
      :pageMode="pageMode"
      :fieldNameAsKey="true"
    >
    </dynamic-generator-form>
  </div>
</template>

<script>
import clearReactive from "../../../../../helpers/clearReactive";
import { TYPE_COMPONETS } from "../../../../../helpers/generatorForm/constants";
import Vue from "vue"
const TYPE_ATTR_LIST = {
    BOOL: "Bool",
    DOUBLE: "Double",
    INT: "Int",
    REFENTITY: "RefEntity",
    STRING: "String"
}

export default {
  name: "SimpleRule",

  props: {
    pageMode: {
      type: String,
      required: true
    },
    title: {
       type: String,
       default: "Простые настройки правила"
    },
    isCreateForm: {
      type: Boolean,
      required: true
    },
    loadData: {
      type: Object,
      required: true
    },
    setDataAttrList: {
      type: Function
    }
  },
  data() {
    return {
      tempLocalData: {},
      converDataToShemeGenerate: null
    };
  },
  created() {
     
  },
  mounted() {
        const tempData = clearReactive(this.loadData.attrList);
        tempData.forEach(item => {
          let value = item.value
          if(item.dataType == TYPE_ATTR_LIST.BOOL) {
              value = item.value == "true" ? true : false
          }  
         Vue.set(this.tempLocalData, item.uuid,  value )
        });
       this.converDataToShemeGenerate = this.getConverDataToShemeGenerate()
  },
  watch:{
    tempLocalData: {
        deep: true,
        handler() {
        const newval = this.tempLocalData      

        let res = [];
        const tempData = clearReactive(this.loadData.attrList);
        tempData.forEach(item => {
          let tempRes = item;
          let value = newval[item.uuid]
           if(item.dataType == TYPE_ATTR_LIST.BOOL) {
              value = value ?  "true" : "false" 
          }    
          tempRes.value = value;
          res.push(tempRes);
        });
        this.setDataAttrList(res); }
    }
  },
  methods: {
  
    getConverDataToShemeGenerate() {
      const nowData = this.loadData;
      const res = [];
      if (nowData.attrList) {
        nowData.attrList.forEach(item => {
          let type = "V_SELECT_COMPLEX";
          let name = item.ruleItemName;
          let uuid = item.uuid;
          let oldParams = item;
          let subListId = 0;
          let SearchType = "RefEntity";
          let relative = null;
          let selectLabel = "entityName";
          let label = item.ruleItemName;

          switch (item.dataType) {
            case TYPE_ATTR_LIST.BOOL: {
              type = TYPE_COMPONETS.CHECKBOX.nameC;
              break;
            }
            case TYPE_ATTR_LIST.DOUBLE: 
            case TYPE_ATTR_LIST.INT: {
              type = TYPE_COMPONETS.INPUT_NUMBER.nameC;
              break;
            }
            case TYPE_ATTR_LIST.REFENTITY: {
              relative = item.valueRefType     
              type = TYPE_COMPONETS.MULTI_SELECT.nameC;
              break;
            }

            case TYPE_ATTR_LIST.STRING: {
              type = TYPE_COMPONETS.INPUT.nameC;
              break;
            }
          }
          res.push({
            type: type,
            description: "",
            main: false,
            default: null,
            colsXS: 6,
            colsSM: 6,
            colsMD: 6,
            colsLG: 6,
            OffsetXS: 0,
            OffsetSM: 0,
            OffsetMD: 0,
            OffsetLG: 0,
            addClass: "",
            name: name,
            fieldName: uuid,
            label: label,
            placeholder: null,
            validationRule: null,
            errName: null,
            cyData: null,
            enable: true,
            readonly: false,
            showTable: true,
            disableInCreate: false,
            relative: relative,
            search: false,
            selectLabel: selectLabel,
            SearchType: SearchType,
            subListId: subListId,
            isReadOnlyAdd: false,
            isReadOnlyEdit: false,
            watchAttr: null,
            oldParams: oldParams
          });
        });
      }
      return res;
    }
  }
};
</script>

<style lang="scss" scoped></style>
