<template>
  <div>
    <show-code :code="fieldInfoList"></show-code>
    <show-code :code="preResult"></show-code>
    <load-block :load="!load"></load-block>
    <the-edit-page
      :scheme="scheme"
      :isEntity="true"
      :loadData="loadData"
      v-if="load"
      :title="title"
      :pageMode="pageMode"
    >
      <simple-rule
        v-if="load"
        :loadData="loadData"
        :pageMode="pageMode"
        :isCreateForm="false"
        :setDataAttrList="setDataAttrList"
      />
      <table-rule-data  
        v-if="load && $route.params.PolicyRuleId > 0 &&  loadData && loadData.isTable"
        :loadData="loadData"
        :pageMode="pageMode"
        :isCreateForm="false"
        :setDataTable="setDataTable"
         />
      <the-controls-edit-page
        :scheme="scheme"
        :loadData="loadData"
        :saveData="saveData"
        :pathBack="pathBack"
        :show-history="!($route.name == 'PolicyRuleCreate')"
        :open-history="openHistory"
        :hasHistory="false"
        configName="policyRule"
        :roleName="nameRoleEntity"
        :pageMode="pageMode"
        :wait="wait"
      ></the-controls-edit-page>
    </the-edit-page>
    <!-- <history-code-enum
      v-if="!($route.name == 'PolicyRuleCreate')"
      :modals="modals"
      :toggleModal="toggleModal"
      :closeHistory="closeHistory"
    ></history-code-enum> -->
  </div>
</template>
<script>
import TheEditPage from "../../../TheEditPage.vue";
import { POLITY_RULE } from "../store/type";
import { READ, UPDATE } from "../../../../store/type";
import TheControlsEditPage from "../../../TheControlsEditPage.vue";
import clearReactive from "../../../../helpers/clearReactive";
import ShowCode from "../../../shared/ShowCode.vue";
import convertFieldInfoListToScheme from "../../../../helpers/converters/convetFieldInfoListToScheme";
import clearNull from "../../../../../src/helpers/clearNull";
import { mapState } from "vuex";

import {
  TYPE_ERRORS,
  FIELD_LANG_POLITICY_ITEM_RULE
} from "../../../../constant";
import notify from "../../../../helpers/notify/notify";
import LoadBlock from "../../../shared/LoadBlock.vue";

import { checkValidate } from "../../../../mixins/validate/checkValidate";
import HistoryCodeEnum from "../../history/HistoryCodeEnum.vue";
import { pageMode } from "../../../../mixins/editPage/pageMode";
import { NAME_OBJECT } from "../../../../RoleConstanans";
import SimpleRule from "./customCorpRules/SimpleRule.vue";
import { generateUUID } from "../../../../helpers/generateUUID";
import TableRuleData from './customCorpRules/TableRuleData.vue';
export default {
  name: "EditPagePolicyRule",
  components: {
    HistoryCodeEnum,
    LoadBlock,
    ShowCode,
    TheControlsEditPage,
    TheEditPage,
    SimpleRule,
    TableRuleData
  },
  mixins: [checkValidate, pageMode],
  data() {
    return {
      wait: false,
      pathBack: `/corpPolicy/${this.$route.params.PolicyId}/edit`,
      scheme: {},
      loadData: null,
      fieldInfoList: null,
      preResult: null,
      load: true,
      modals: {
        history: false
      },
      routeNameShow: "PolicyRuleShow",
      routeNameCreate: "PolicyRuleCreate"
    };
  },
  computed: {
    ...mapState({
      lang: state => state.common.lang
    }),
    nameRoleEntity() {
      return NAME_OBJECT.policyRule;
    },
    title() {
      return this.pageTitle(
        this.$t("Просмотр правил политики"),
        this.$t("Создание правил политики"),
        this.$t("Редактирование правил политики")
      );
    }
  },
  watch: {
    lang() {
      this.initData();
    }
  },
  created() {
    this.initData();
  },
  methods: {
    toggleModal(name) {
      this.modals[name] = !this.modals[name];
    },
    openHistory() {
      this.toggleModal("history");
    },
    closeHistory() {
      this.toggleModal("history");
      this.initData();
    },

    initData() {
      this.load = false;
      this.loadData = null;

      this.$store
        .dispatch(POLITY_RULE + READ, {
          id: this.$route.params.PolicyRuleId || "0",
          self: this,
          PolicyId: this.$route.params.PolicyId,
          ruleId: this.$route.query.ruleId
        })
        .then(res => {
          this.loadData = res.object;
          let fieldInfoList = res.fieldInfoList;

          this.fieldInfoList = fieldInfoList; //del in future
          this.scheme.configForm = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: FIELD_LANG_POLITICY_ITEM_RULE
          });
          this.preResult = convertFieldInfoListToScheme({
            fieldInfoList,
            langFieldName: FIELD_LANG_POLITICY_ITEM_RULE
          }); //del in future

          this.loadData.codeId = this.$route.params.CodeId;
          this.loadData.policyId = this.$route.params.PolicyId;

          if (this.loadData.attrList) {
            this.loadData.attrList = this.loadData.attrList.map(item => {
              return {
                ...item,
                uuid: generateUUID()
              };
            });
          }

            if (this.loadData.attrTableList)  {
              this.loadData.attrTableList = this.loadData.attrTableList.map(item=>{
                let itemNow = item
                  itemNow = itemNow.map( itemVar => {
                    return {
                            ...itemVar,
                            uuid: generateUUID()
                          };
                  } )
                return itemNow
              })
            }

          this.load = true;
        });
    },
    saveData() {
      if (this.validateData() == false) {
        return;
      }
      let saveData = clearReactive(this.loadData);
      saveData = clearNull(saveData);
      this.wait = true;
      this.$store
        .dispatch(POLITY_RULE + UPDATE, {
          id: this.$route.params.PolicyRuleId,
          saveData: saveData,
          self: this,
          PolicyId: this.$route.params.PolicyId
        })
        .then(res => {
          this.wait = false;
          if (res.errors.length == 0) {
            notify(this, this.$t("Успешно"), TYPE_ERRORS.SUCCESS);
            this.$router.push({ path: `/corpPolicy/${this.$route.params.PolicyId}/policyRule/${res.object}/edit?ruleId=${this.$route.query.ruleId}` });
          }
        })
        .catch(err => {
          console.error(err);
          this.wait = false;
        });
    },

    setDataAttrList(val) {
      if (val) {
        val.forEach(itemVal => {
          this.loadData.attrList = this.loadData.attrList.map(item => {
            if (item.uuid == itemVal.uuid) {
              return { ...item, value: itemVal.value };
            }
            return item;
          });
        });
      }
    },
    setDataTable(val) {
        this.initData()
    }
  }
};
</script>
